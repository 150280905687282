import React, { useState, useEffect } from "react";
import BlogCard from "./BlogCard";
import Spinner from "../Spinners/Spinner";

function BlogLanding() {
  const [blogs, setBlog] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const fetchBlog = async () => {
    setIsLoading(true);
    try {
      const response = await fetch(
        "https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts"
      );
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
      const data = await response.json();
      setBlog(data);
      console.log(data); // Note: use `data` instead of `blogs`
    } catch (error) {
      console.error("Fetching error:", error);
      // Handle or set error state if necessary
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchBlog();
  }, []);

  if (isLoading)
    return (
      <div>
        <Spinner />
      </div>
    );
  return (
    <div class="grid place-items-center">
      {blogs.map((blog) => (
        <BlogCard key={blog.id} blog={blog} />
      ))}
    </div>
  );
}

export default BlogLanding;
