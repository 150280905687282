import React, { useEffect, useState, useRef } from "react";
import DOMPurify from "dompurify";
import "./blog.css";
import { useParams } from "react-router-dom";
import { User } from "@nextui-org/user";
import { Divider } from "@nextui-org/react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faComments } from "@fortawesome/free-regular-svg-icons";
import { faThumbsUp } from "@fortawesome/free-regular-svg-icons";
import axios from "axios";
import Spinner from "../Spinners/Spinner";
import Comments from "./lib/Comments";

function BlogSinglePost() {
  const [blog, setBlog] = useState(null);
  const [media, setMediaUrl] = useState(null);
  const [commentCount, setCommentCount] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const { id } = useParams();
  const scrollRef = useRef(null);

  useEffect(() => {
    const fetchBlog = async () => {
      setIsLoading(true);
      try {
        const postUrl = `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/posts/${id}`;
        const postResponse = await fetch(postUrl);
        if (!postResponse.ok) {
          throw new Error(`HTTP error! status: ${postResponse.status}`);
        }
        const postData = await postResponse.json();
        console.log("blog", postData);
        setBlog(postData);

        // Extract the media ID from the post data
        const { featured_media, author } = postData;

        if (featured_media && author) {
          // Fetch the media details if there is a media ID
          const getImageUrl = await axios.get(
            `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/media/${featured_media}`
          );

          const getAuthor = await axios.get(
            `https://wp.wanij.com/wordpress/index.php?rest_route=/wp/v2/users/${author}`
          );

          Promise.all([getImageUrl, getAuthor])
            .then((res) => {
              console.log("res", res);
              setMediaUrl({
                imgUrl: res[0].data.guid.rendered,
                author: res[1].data.name,
                authorImg: res[1].data.avatar_urls[48],
                isLoaded: true,
              });
            })
            .catch((err) => {
              console.log("err", err);
            });
        }
      } catch (e) {
        console.error("Fetching error:", e);
        setError("Failed to fetch blog post or media. Please try again later.");
      } finally {
        setIsLoading(false);
      }
    };

    fetchBlog();
  }, [id]);

  const scrollToSection = () => {
    scrollRef.current.scrollIntoView({ behavior: "smooth" });
  };

  if (isLoading)
    return (
      <>
        <Spinner />
      </>
    );
  if (error) return <h1>{error}</h1>;
  if (!blog) return <h1>No blog post found</h1>;

  const sanitizedContent = DOMPurify.sanitize(blog.content.rendered);

  return (
    <div className="w-full max-w-4xl mx-auto px-4 sm:px-6 lg:px-8">
      <div className="bg-background/60 dark:bg-default-100/50 rounded-lg shadow-none overflow-hidden">
        <div className="p-6 sm:p-8 md:p-10">
          <h1 className="text-2xl sm:text-3xl md:text-4xl font-bold mb-5 ">
            {blog.title.rendered}
          </h1>
          <div className="flex justify-between items-start">
            <div className="flex flex-col gap-0 mb-0">
              <User
                name={media.author}
                description={new Date(blog.date).toLocaleDateString("en-US", {
                  year: "numeric",
                  month: "long",
                  day: "numeric",
                })}
                avatarProps={{
                  src: `${media.authorImg}`,
                }}
              />
            </div>
          </div>
          <Divider className="my-5 mb-2" />
          <div className="flex justify-between items-start">
            <div className="flex mb-0">
              {" "}
              <p className="text-bold  capitalize  mr-5 mb-2">
                <button>
                  {" "}
                  <FontAwesomeIcon icon={faThumbsUp} className="text-lg" />
                  <span className="ml-0.5 text-sm text-default-400"> 25k</span>
                </button>
              </p>
              <p className="text-bold  capitalize  mr-3 mb-2">
                <button onClick={scrollToSection}>
                  <FontAwesomeIcon icon={faComments} className="text-lg" />
                  <span className="ml-0.5 text-sm text-default-400">
                    {" "}
                    {commentCount}
                  </span>
                </button>
              </p>
            </div>
            <div className="mr-2">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                fill="none"
                viewBox="0 0 24 24"
              >
                <path
                  fill="currentColor"
                  fill-rule="evenodd"
                  d="M15.218 4.931a.4.4 0 0 1-.118.132l.012.006a.45.45 0 0 1-.292.074.5.5 0 0 1-.3-.13l-2.02-2.02v7.07c0 .28-.23.5-.5.5s-.5-.22-.5-.5v-7.04l-2 2a.45.45 0 0 1-.57.04h-.02a.4.4 0 0 1-.16-.3.4.4 0 0 1 .1-.32l2.8-2.8a.5.5 0 0 1 .7 0l2.8 2.79a.42.42 0 0 1 .068.498m-.106.138.008.004v-.01zM16 7.063h1.5a2 2 0 0 1 2 2v10a2 2 0 0 1-2 2h-11c-1.1 0-2-.9-2-2v-10a2 2 0 0 1 2-2H8a.5.5 0 0 1 .35.15.5.5 0 0 1 .15.35.5.5 0 0 1-.15.35.5.5 0 0 1-.35.15H6.4c-.5 0-.9.4-.9.9v10.2a.9.9 0 0 0 .9.9h11.2c.5 0 .9-.4.9-.9v-10.2c0-.5-.4-.9-.9-.9H16a.5.5 0 0 1 0-1"
                  clip-rule="evenodd"
                ></path>
              </svg>
            </div>
          </div>
          <Divider className="my-10 mt-0" />
          <div className="flex justify-center items-center mb-5 bg-gray-100 rounded-lg ">
            <img
              src={media.imgUrl}
              alt={"image"}
              className="max-w-full h-auto rounded-lg shadow-md"
            />
          </div>

          <div
            className="prose prose-sm sm:prose lg:prose-lg mx-auto"
            dangerouslySetInnerHTML={{ __html: sanitizedContent }}
          />
        </div>
      </div>
      <div ref={scrollRef}>
        <Comments postId={blog.id} setCommentCount={setCommentCount} />
      </div>
    </div>
  );
}

export default BlogSinglePost;
